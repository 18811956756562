import { cloneDeep, toSafeInteger/* , filter  */, find } from "lodash";
import ApiService from "@/core/services/api.service";
import ObjPath from "object-path";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

import { SET_CUSTOMER_DATA } from "./customer.module.js";
import { SET_CUSTOMER, SET_PROPERTY, SET_BILLING, SET_BILLING_CONTACT, SET_PROPERTY_CONTACT, SET_USER_CONTACT, SET_PIC_ADDRESS } from "./visit.module.js";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import moment from "moment-timezone";

const todayDate = moment(new Date());

/* const formatDate = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format("DD MMMM YYYY hh:mm A");
  }
} */
const adjustedStartTime = moment(todayDate)
    .startOf("hour")
    .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");
const adjustedStartEnd = moment(todayDate)
    .add(1, "hour")
    .startOf("hour")
    .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");
const initialSchedule = {
    start_date: moment(todayDate).format("YYYY-MM-DD"),
    end_date: moment(todayDate).format("YYYY-MM-DD"),
    start_time: adjustedStartTime.format("hh:mm A"),
    end_time: adjustedStartEnd.format("hh:mm A"),
};
const dbOneOffSchedule = {
    start_date: initialSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
    end_date: initialSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
    start_time: initialSchedule.start_time /* FORMAT - hh:mm A*/,
    end_time: initialSchedule.end_time /* FORMAT - hh:mm A*/,
};
// action types
export const SET_TO_CUSTOMER = "setDeliveyToCustomer";
export const SET_TO_CUSTOMER_TYPE = "setTypeToCustomer";
export const SET_TO_DELIVERY_CUSTOMER_TYPE = "setTypeToDeliveryCustomer";
export const SET_TO_PROPERTY = "setDeliveryToProperty";
export const SET_TO_PROPERTY_CONTACT = "setDeliveryToPropertyContact";
export const SET_TO_BILLING = "setDeliveryToBilling";
export const SET_TO_BILLING_CONTACT = "setDeliveryToBillingContact";
export const RESET_TO_STATE = "resetDeliveryToState";
export const RESET_TO_CREATE_STATE = "resetDeliveryToCreateState";
export const SET_TO_LOADING = "deliveryloadingTo";
export const SET_CUSTOMER_DLV_TYPE = "setDeliveyTypeToCustomer";
export const UPDATE_DEFAULT_DATE = "JtxDeliveryUpdateDefaultDate";
export const RESET_ENGINEER = "JtxDeliveryResetEngineer";
export const REMOVE_ENGINEER = "JtxDeliveryRemoveEngineer";
export const GET_AVAILABLE_ENGINEER = "JtxDeliveryGetAvailableEngineer";
export const SET_AVAILABLE_ENGINEER = "JtxDeliverySetAvailableEngineer";
export const UPDATE_SCHEDULE_ENGINEER = "JtxDeliveryUpdateScheduleEngineer";
export const UPDATE_SCHEDULE_STATE = "JtxDeliveryUpdateScheduleState";
export const CREATE_OR_UPDATE = "JtxDeliveryCreateOrUpdate";
export const SET_LOADING = "JtxDeliverySetJobLoading";
export const SET_TO_DIALOG_STATUS = "setToDialogStatus";
export const SET_FROM_ADDRESS_FLAG = "setAdressFlag";
export const SET_FROM_PAYLOAD_ADDRESS_FLAG = "setAdressPayloadFlag";
export const SET_TO_ADDRESS_FLAG = "setToAdressFlag";
export const SET_TO_PAYLOAD_ADDRESS_FLAG = "setToAdressPayloadFlag";
export const SET_DELIVERY_TYPE = "setDeliveryType";


export const GET_DELIVERY = "JtxDeliveryGet";
export const SET_DELIVERY = "JtxDeliverySet";


export default {
    state: {
        db_from_address_payload_flag: 0,
        db_from_address_flag: 0,
        db_to_address_payload_flag: 0,
        db_to_address_flag: 0,
        db_dialog: false,
        dbDefaultStartedAt: null,
        dbDefaultFinishedAt: null,
        db_customer: {},
        db_to_customer_type: 'customer-list',
        db_delivery_customer_type: 'to-delivery',
        db_property: {},
        db_property_contact: {},
        db_billing: {},
        db_billing_contact: {},
        db_loading: false,
        db_customer_dlv_type: 'from_customer',
        dbOneOffSchedule: cloneDeep(dbOneOffSchedule),
        dbAvailableTeam: [],
        dbAssignedTeam: [],
        dbAssignedTeamObject: [],
        dbJobLoading: false,
        dbDeliveryEditData: {},
        dbDeliveryType: null,

    },
    getters: {

        vToCustomerId(state) {
            return state.db_customer?.id;
        },
        vToCustomerType(state) {
            return state.db_to_customer_type;
        },
        vToDelivery(state) {
            return state.db_delivery_customer_type;
        },
        vToPropertyId(state) {
            return state.db_property?.id;
        },
        vToPropertyContactId(state) {
            return state.db_property_contact?.id;
        },
        vToBillingId(state) {
            return state.db_billing?.id;
        },
        vToBillingContactId(state) {
            return state.db_billing_contact?.id;
        },

        vToCustomer(state) {
            return state.db_customer;
        },
        vToProperty(state) {
            return state.db_property;
        },
        vToPropertyContact(state) {
            return state.db_property_contact;
        },
        vToBilling(state) {
            return state.db_billing;
        },
        vToBillingContact(state) {
            return state.db_billing_contact;
        },
        vToCustomerDlvType(state) {
            return state.db_customer_dlv_type;
        },
        defaultDeliveryStartedAt(state) {
            return state.dbDefaultStartedAt;
        },
        defaultDeliveryFinishedAt(state) {
            return state.dbDefaultFinishedAt;
        },
        deliverySchedule(state) {
            return state.dbOneOffSchedule;
        },
        assignedDeliveryTeam(state) {
            return state.dbAssignedTeam;
        },
        availableDeliveryTeam(state) {
            return state.dbAvailableTeam;
        },
        assignedDeliveryTeamObject(state) {
            return state.dbAssignedTeamObject;
        },
        deliveryLoading(state) {
            return state.dbJobLoading;
        },
        deliveryType(state) {
            return state.dbDeliveryType;
        },
        reToCustomerDialog(state) {
            return state.db_dialog;
        },
        deliveryEditData(state) {
            return state.dbDeliveryEditData;
        },
        companyFromBillingFlag(state) {
            return state.db_from_address_flag;
        },
        companyFromPayloadBillingFlag(state) {
            return state.db_from_address_payload_flag;
        },
        companyToBillingFlag(state) {
            return state.db_to_address_flag;
        },
        companyToPayloadBillingFlag(state) {
            return state.db_to_address_payload_flag;
        },
    },
    actions: {

        [RESET_TO_STATE](context) {
            context.commit(RESET_TO_STATE);
        },
        [RESET_TO_CREATE_STATE](context) {
            context.commit(RESET_TO_CREATE_STATE);
        },


        [SET_TO_CUSTOMER](context, payload) {
            return new Promise((resolve, reject) => {
                context.commit(SET_TO_CUSTOMER, {});
                context.commit(SET_TO_PROPERTY, {});
                context.commit(SET_TO_PROPERTY_CONTACT, {});
                context.commit(SET_TO_BILLING, {});
                context.commit(SET_TO_BILLING_CONTACT, {});

                ApiService.setHeader();
                context.commit(SET_TO_LOADING, true);

                ApiService.get(`customer-with-relation/${payload.id}/${context?.state?.db_to_customer_type}`)
                    .then((response) => {
                        context.dispatch(SET_CUSTOMER_DATA, response);
                        context.commit(
                            SET_TO_CUSTOMER,
                            ObjPath.get(response, "data.data.customer", {})
                        );
                        context.commit(
                            SET_TO_PROPERTY,
                            ObjPath.get(response, "data.data.property", {})
                        );
                        context.commit(
                            SET_TO_PROPERTY_CONTACT,
                            ObjPath.get(response, "data.data.property_contact_person", {})
                        );
                        context.commit(
                            SET_TO_BILLING,
                            ObjPath.get(response, "data.data.billing", {})
                        );
                        context.commit(
                            SET_TO_BILLING_CONTACT,
                            ObjPath.get(response, "data.data.billing_contact_person", {})
                        );

                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        context.commit(SET_TO_LOADING, false);
                    });
            });
        },
        [SET_TO_PROPERTY](context, payload) {
            context.commit(SET_TO_PROPERTY, payload);
        },
        [SET_TO_PROPERTY_CONTACT](context, payload) {
            context.commit(SET_TO_PROPERTY_CONTACT, payload);
        },
        [SET_TO_BILLING](context, payload) {
            context.commit(SET_TO_BILLING, payload);
        },
        [SET_TO_BILLING_CONTACT](context, payload) {
            context.commit(SET_TO_BILLING_CONTACT, payload);
        },
        [SET_TO_LOADING](context, payload) {
            context.commit(SET_TO_LOADING, payload);
        },
        [SET_CUSTOMER_DLV_TYPE](context, payload) {
            context.commit(SET_CUSTOMER_DLV_TYPE, payload);
        },
        [SET_FROM_ADDRESS_FLAG](context, payload) {
            context.commit(SET_FROM_ADDRESS_FLAG, payload);
        },
        [SET_FROM_PAYLOAD_ADDRESS_FLAG](context, payload) {
            context.commit(SET_FROM_PAYLOAD_ADDRESS_FLAG, payload);
        },
        [SET_TO_ADDRESS_FLAG](context, payload) {
            context.commit(SET_TO_ADDRESS_FLAG, payload);
        },
        [SET_TO_PAYLOAD_ADDRESS_FLAG](context, payload) {
            context.commit(SET_TO_PAYLOAD_ADDRESS_FLAG, payload);
        },

        [UPDATE_DEFAULT_DATE](context, payload) {
            let startedAt = null;
            let finishedAt = null;

            if (payload == "single-delivery") {
                startedAt = `${context.state.dbOneOffSchedule.start_date} ${context.state.dbOneOffSchedule.start_time}`;
                finishedAt = `${context.state.dbOneOffSchedule.end_date} ${context.state.dbOneOffSchedule.end_time}`;
            }

            if (context.state.dbDeliveryEditData?.id) {
                return false;
            }

            context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });
        },

        [GET_AVAILABLE_ENGINEER](context, payload) {
            context.commit(SET_LOADING, true);
            context.commit(RESET_ENGINEER);
            ApiService.setHeader();
            ApiService.query("delivery/available-drivers", payload)
                .then(({ data }) => {
                    context.commit(SET_AVAILABLE_ENGINEER, data?.data ?? []);
                    if (!data?.data?.length) {
                        ErrorEventBus.$emit(
                            "update:error",
                            InitializeError("Oops.. No Drivers are available.")
                        );
                    }
                })
                .catch(() => {
                    context.commit(SET_AVAILABLE_ENGINEER, []);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        },
        [CREATE_OR_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                context.commit(SET_LOADING, true);
                let requestType = "post";
                let requestURL = "delivery";
                let deliveryId = payload?.id ?? 0;
                if (deliveryId) {
                    requestType = "put";
                    requestURL = `delivery/${deliveryId}`;
                }
                ApiService.setHeader();
                ApiService[requestType](requestURL, payload)
                    .then((data) => {
                        resolve(data.data);
                    })
                    .catch(() => {
                        reject(false);
                    })
                    .finally(() => {
                        context.commit(SET_LOADING, false);
                    });
            });
        },

        [GET_DELIVERY](context, { delivery }) {
            return new Promise((resolve, reject) => {
                context.commit(SET_LOADING, true);
                context.commit(SET_DELIVERY, {});
                ApiService.setHeader();
                ApiService.get(`delivery/v1/${delivery}`)
                    .then(({ data }) => {

                        if (data?.data) {
                            const startedAt = data?.data?.started_at;
                            const finishedAt = data?.data?.finished_at;

                            context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });

                            context.commit(UPDATE_SCHEDULE_STATE, {
                                key: "dbOneOffSchedule.start_date",
                                value: moment(startedAt).format("YYYY-MM-DD"),
                            });

                            context.commit(UPDATE_SCHEDULE_STATE, {
                                key: "dbOneOffSchedule.end_date",
                                value: moment(finishedAt).format("YYYY-MM-DD"),
                            });

                            context.commit(UPDATE_SCHEDULE_STATE, {
                                key: "dbOneOffSchedule.start_time",
                                value: moment(startedAt).format("hh:mm A"),
                            });

                            context.commit(UPDATE_SCHEDULE_STATE, {
                                key: "dbOneOffSchedule.end_time",
                                value: moment(finishedAt).format("hh:mm A"),
                            });

                            context.commit(SET_CUSTOMER, data?.data?.customer_relation ?? {});
                            context.commit(SET_PROPERTY, data?.data?.property_relation ?? {});
                            context.commit(
                                SET_PROPERTY_CONTACT,
                                data?.data?.property_contact_person_relation ?? {}
                            );
                            context.commit(SET_BILLING, data?.data?.billing_relation ?? {});
                            context.commit(
                                SET_BILLING_CONTACT,
                                data?.data?.billing_contact_person_relation ?? {}
                            );
                            context.commit(
                                SET_USER_CONTACT,
                                data?.data?.picAddressuser ?? {}
                            );
                            context.commit(
                                SET_PIC_ADDRESS,
                                data?.data?.picAddress ?? {}
                            );



                            context.commit(SET_TO_CUSTOMER, data?.data?.to_customer_relation ?? {});
                            context.commit(SET_TO_PROPERTY, data?.data?.to_property_relation ?? {});
                            context.commit(
                                SET_TO_PROPERTY_CONTACT,
                                data?.data?.to_property_contact_person_relation ?? {}
                            );
                            context.commit(SET_TO_BILLING, data?.data?.to_billing_relation ?? {});
                            context.commit(
                                SET_TO_BILLING_CONTACT,
                                data?.data?.to_billing_contact_person_relation ?? {}
                            );
                            context.commit(UPDATE_SCHEDULE_STATE, {
                                key: "dbJobOption.barcode",
                                value: data?.data?.barcode,
                            });
                            context.commit(
                                SET_AVAILABLE_ENGINEER,
                                data?.data?.available_team ?? []
                            );

                            context.commit(UPDATE_SCHEDULE_STATE, {
                                key: "dbAssignedTeamObject",
                                value: data?.data?.team,
                            });
                            context.commit(UPDATE_SCHEDULE_STATE, {
                                key: "dbAssignedTeam",
                                value: data?.data?.team?.map((row) => row.engineer_id),
                            });

                            context.commit(SET_DELIVERY, data?.data, {});
                        }
                        resolve(true);
                    })
                    .catch(() => {
                        context.commit(SET_DELIVERY, {});
                        reject(false);
                    })
                    .finally(() => {
                        context.commit(SET_LOADING, false);
                    });
            });
        },

    },
    mutations: {

        [RESET_TO_CREATE_STATE](state) {
            state.db_from_address_flag = 0;
            state.db_from_address_payload_flag = 0;
            state.db_to_address_flag = 0;
            state.db_to_address_payload_flag = 0;
            state.db_customer = {};
            state.db_to_customer_type = 'customer-list';
            state.db_delivery_customer_type = 'to-delivery';
            state.db_property = {};
            state.db_property_contact = {};
            state.db_billing = {};
            state.db_customer_dlv_type = 'from_customer',
                state.db_loading = false;
            state.dbDefaultStartedAt = null;
            state.dbDefaultFinishedAt = null;
            state.dbOneOffSchedule = cloneDeep(dbOneOffSchedule);
            state.dbAvailableTeam = [];
            state.dbAssignedTeam = [];
            state.dbAssignedTeamObject = [];
            state.dbDeliveryType = null;
            state.dbDeliveryEditData = {};

        },
        [RESET_TO_STATE](state) {
            state.db_from_address_flag = 0;
            state.db_from_address_payload_flag = 0;
            state.db_to_address_flag = 0;
            state.db_to_address_payload_flag = 0;
            state.db_customer = {};
            state.db_property = {};
            state.db_to_customer_type = 'customer-list';
            state.db_delivery_customer_type = 'to-delivery';
            state.db_property_contact = {};
            state.db_billing = {};
            state.db_customer_dlv_type = 'from_customer',
                state.db_loading = false;
            state.dbDefaultStartedAt = null;
            state.dbDefaultFinishedAt = null;
            state.dbOneOffSchedule = cloneDeep(dbOneOffSchedule);
            state.dbAvailableTeam = [];
            state.dbAssignedTeam = [];
            state.dbAssignedTeamObject = [];
            state.dbJobLoading = false;
            state.dbDeliveryType = null;
            state.dbDeliveryEditData = {};
        },

        [SET_TO_CUSTOMER](state, payload) {
            state.db_customer = cloneDeep(payload);
        },
        [SET_TO_CUSTOMER_TYPE](state, payload) {
            state.db_to_customer_type = cloneDeep(payload);
        },
        [SET_TO_DELIVERY_CUSTOMER_TYPE](state, payload) {
            state.db_delivery_customer_type = cloneDeep(payload);
        },
        [SET_TO_PROPERTY](state, payload) {
            state.db_property = cloneDeep(payload);
        },
        [SET_TO_PROPERTY_CONTACT](state, payload) {
            state.db_property_contact = cloneDeep(payload);
        },
        [SET_TO_BILLING](state, payload) {
            state.db_billing = cloneDeep(payload);
        },
        [SET_TO_BILLING_CONTACT](state, payload) {
            state.db_billing_contact = cloneDeep(payload);
        },
        [SET_TO_LOADING](state, payload) {
            state.db_loading = payload;
        },
        [SET_CUSTOMER_DLV_TYPE](state, payload) {
            state.db_customer_dlv_type = payload;
        },
        [SET_FROM_ADDRESS_FLAG](state, payload) {
            state.db_from_address_flag = payload;
        },
        [SET_FROM_PAYLOAD_ADDRESS_FLAG](state, payload) {
            state.db_from_address_payload_flag = payload;
        },
        [SET_TO_ADDRESS_FLAG](state, payload) {
            state.db_to_address_flag = payload;
        },
        [SET_TO_PAYLOAD_ADDRESS_FLAG](state, payload) {
            state.db_to_address_payload_flag = payload;
        },
        [UPDATE_DEFAULT_DATE](state, { startedAt, finishedAt }) {
            state.dbDefaultStartedAt = startedAt
                ? moment(startedAt).format("YYYY-MM-DD HH:mm")
                : null;
            state.dbDefaultFinishedAt = finishedAt
                ? moment(finishedAt).format("YYYY-MM-DD HH:mm")
                : null;
        },
        [RESET_ENGINEER](state) {
            state.dbAssignedTeam = [];
            state.dbAvailableTeam = [];
            state.dbAssignedTeamObject = [];
        },
        [REMOVE_ENGINEER](state, payload) {
            state.dbAssignedTeam = cloneDeep(
                state.dbAssignedTeam.filter((obj) => payload != obj)
            );
            state.dbAssignedTeamObject = cloneDeep(
                state.dbAssignedTeamObject.filter((obj) => payload != obj.engineer_id)
            );
        },
        [UPDATE_SCHEDULE_ENGINEER](state) {
            let dbAvailableTeam = cloneDeep(state.dbAvailableTeam);
            let dbAssignedTeam = cloneDeep(state.dbAssignedTeam);
            let objectAssignedTeam = [];

            for (let i = 0; i < dbAssignedTeam.length; i++) {
                let exists = find(dbAvailableTeam, { engineer_id: toSafeInteger(dbAssignedTeam[i]) });
                if (exists) {
                    objectAssignedTeam.push(exists);
                }
            }
            state.dbAssignedTeamObject = cloneDeep(objectAssignedTeam);
        },
        [SET_AVAILABLE_ENGINEER](state, payload) {
            state.dbAvailableTeam = cloneDeep(payload);
            /*  if (!payload.length) {
                 ErrorEventBus.$emit(
                     "update:error",
                     InitializeError("Oops.. No Drivers are available.")
                 );
             } */
        },

        [SET_LOADING](state, payload) {
            state.dbJobLoading = payload;
        },
        [SET_TO_DIALOG_STATUS](state, payload) {
            state.db_dialog = payload;
        },
        [SET_DELIVERY](state, payload) {
            state.dbDeliveryEditData = cloneDeep(payload);
        },
        [UPDATE_SCHEDULE_STATE](state, { key, value }) {
            ObjPath.set(state, key, cloneDeep(value));
        },
        [SET_DELIVERY_TYPE](state, payload) {
            state.dbDeliveryType = payload;
        },

    },
};
