import { cloneDeep } from "lodash";
import ApiService from "@/core/services/api.service";
import ObjPath from "object-path";

export const SET_CUSTOMER = "setSelectionCustomer";
export const SET_CUSTOMER_DATA = "setSelectionCustomerData";
export const SET_PROPERTY = "setSelectionProperty";
export const SET_PROPERTY_CONTACT = "setSelectionPropertyContact";
export const SET_BILLING = "setSelectionBilling";
export const SET_BILLING_CONTACT = "setSelectionBillingContact";
export const SET_LOADING = "setCustomerSelectionLoading";
export const CLEAR_CUSTOMER = "clearTransactionCustomer";
export const GET_CUSTOMER_RELATED = "getCustomerRelated";
export const SET_CUSTOMER_RELATED = "setCustomerRelated";
export const SET_DIALOG_STATUS = "setDialogStatus";
export const SET_CREATE_DIALOG_STATUS = "setCreateDialogStatus";
export const SET_CUSTOMER_LISTING_FILTER = "setCustomerListingFilter";
export const SET_DEFAULT_DUP_VISIT = "setDefaultDupVisit";
export const CLOSE_DUP_DIALOG = "closeDupDialog";
export const SET_EDIT_MODE = "setEditMode";

// action types

export default {
  state: {
    db_dialog: false,
    db_filter_listing: {
      customer: null,
      billing: null,
      property: null,
    },
    db_dup_dialog: false,
    db_edit_mode: false,
    db_default_dup_visit: [],
    db_dup_visit: [],
    db_create_dialog: false,
    db_selection_loading: false,
    db_selection_customer: {},
    db_selection_property: {},
    db_selection_property_contact: {},
    db_selection_billing: {},
    db_selection_billing_contact: {},
    db_ctx_related_invoices: [],
    db_ctx_related_quotations: [],
    db_ctx_related_visits: [],
  },
  getters: {
    vDupVisit(state) {
      return state.db_dup_visit;
    },
    vDupDialog(state) {
      return state.db_dup_dialog;
    },
    vCustomerFilterKey(state) {
      return state.db_filter_listing;
    },
    vCustomerFilter(state) {
      return state.db_filter_listing;
    },
    vCustomerDialog(state) {
      return state.db_dialog;
    },
    vCustomerCreateDialog(state) {
      return state.db_create_dialog;
    },
    vRelatedInvoices(state) {
      return state.db_ctx_related_invoices;
    },
    vRelatedQuotations(state) {
      return state.db_ctx_related_quotations;
    },
    vRelatedVisits(state) {
      return state.db_ctx_related_visits;
    },
    vCustomerLoading(state) {
      return state.db_selection_loading;
    },
    reCustomerId(state) {
      return state.db_selection_customer?.id;
    },
    rePropertyId(state) {
      return state.db_selection_property?.id;
    },
    rePropertyContactId(state) {
      return state.db_selection_property_contact?.id;
    },
    reBillingId(state) {
      return state.db_selection_billing?.id;
    },
    reBillingContactId(state) {
      return state.db_selection_billing_contact?.id;
    },
    reCustomer(state) {
      return state.db_selection_customer;
    },
    reProperty(state) {
      return state.db_selection_property;
    },
    rePropertyContact(state) {
      return state.db_selection_property_contact;
    },
    reBilling(state) {
      return state.db_selection_billing;
    },
    reBillingContact(state) {
      return state.db_selection_billing_contact;
    },
    reCustomerDialog(state) {
      return state.db_dialog;
    },
  },
  actions: {
    [GET_CUSTOMER_RELATED](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(`customer-related/${payload.id}`, {
          "d-type": payload.type,
        })
          .then((response) => {
            context.commit(SET_CUSTOMER_RELATED, response);
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [CLEAR_CUSTOMER](context) {
      context.commit(CLEAR_CUSTOMER);
    },
    [SET_LOADING](context, payload) {
      context.commit(SET_LOADING, payload);
    },
    [SET_PROPERTY_CONTACT](context, payload) {
      context.commit(SET_PROPERTY_CONTACT, payload);
    },
    [SET_BILLING_CONTACT](context, payload) {
      context.commit(SET_BILLING_CONTACT, payload);
    },
    [SET_PROPERTY](context, payload) {
      context.commit(SET_PROPERTY, payload);
    },
    [SET_BILLING](context, payload) {
      context.commit(SET_BILLING, payload);
    },
    /* Set customer Data from visit.module.js */
    [SET_CUSTOMER_DATA](context, payload) {
      context.commit(
        SET_CUSTOMER,
        ObjPath.get(payload, "data.data.customer", {})
      );
      context.commit(
        SET_PROPERTY,
        ObjPath.get(payload, "data.data.property", {})
      );
      context.commit(
        SET_PROPERTY_CONTACT,
        ObjPath.get(payload, "data.data.property_contact_person", {})
      );
      context.commit(
        SET_BILLING,
        ObjPath.get(payload, "data.data.billing", {})
      );
      context.commit(
        SET_BILLING_CONTACT,
        ObjPath.get(payload, "data.data.billing_contact_person", {})
      );
    },
    [SET_CUSTOMER](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(SET_CUSTOMER, {});
        context.commit(SET_PROPERTY, {});
        context.commit(SET_PROPERTY_CONTACT, {});
        context.commit(SET_BILLING, {});
        context.commit(SET_BILLING_CONTACT, {});

        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.get(`customer-with-relation/${payload.id}`)
          .then((response) => {
            context.commit(
              SET_CUSTOMER,
              ObjPath.get(response, "data.data.customer", {})
            );
            context.commit(
              SET_PROPERTY,
              ObjPath.get(response, "data.data.property", {})
            );
            context.commit(
              SET_PROPERTY_CONTACT,
              ObjPath.get(response, "data.data.property_contact_person", {})
            );
            context.commit(
              SET_BILLING,
              ObjPath.get(response, "data.data.billing", {})
            );
            context.commit(
              SET_BILLING_CONTACT,
              ObjPath.get(response, "data.data.billing_contact_person", {})
            );
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
  },
  mutations: {
    [SET_CUSTOMER_LISTING_FILTER](state, payload) {
      state.db_filter_listing = cloneDeep(payload);
    },
    [SET_CUSTOMER_RELATED](state, payload) {
      state.db_ctx_related_invoices = payload?.data?.data?.invoices ?? [];
      state.db_ctx_related_quotations = payload?.data?.data?.quotations ?? [];
      state.db_ctx_related_visits = payload?.data?.data?.visits ?? [];
    },
    [CLEAR_CUSTOMER](state) {
      state.db_selection_loading = false;
      state.db_selection_customer = {};
      state.db_selection_property = {};
      state.db_dup_dialog = false;
      state.db_edit_mode = false;
      state.db_default_dup_visit = [];
      state.db_dup_visit = [];
      state.db_selection_property_contact = {};
      state.db_selection_billing = {};
      state.db_selection_billing_contact = {};
      state.db_ctx_related_invoices = [];
      state.db_ctx_related_quotations = [];
      state.db_ctx_related_visits = [];
      state.db_filter_listing = [];
    },
    [SET_LOADING](state, payload) {
      state.db_selection_loading = payload;
    },
    [SET_EDIT_MODE](state, payload) {
      state.db_edit_mode = payload;
    },
    [SET_DEFAULT_DUP_VISIT](state, payload) {
      if (!state.db_edit_mode) {
        state.db_default_dup_visit = cloneDeep(payload);
      }
    },
    [CLOSE_DUP_DIALOG](state) {
      state.db_dup_dialog = false;
    },
    [SET_PROPERTY](state, payload) {
      state.db_selection_property = cloneDeep(payload);
      if (!state.db_edit_mode) {
        const dup_visit = state.db_default_dup_visit.filter(
          (row) => row.property == payload.id
        );
        if (dup_visit && dup_visit.length) {
          state.db_dup_visit = cloneDeep(dup_visit);
          state.db_dup_dialog = false;
          if (state.db_dup_visit && state.db_dup_visit?.length) {
            state.db_dup_dialog = true;
          }
        }
      }
    },
    [SET_CREATE_DIALOG_STATUS](state, payload) {
      state.db_dialog = false;
      state.db_create_dialog = payload;
    },
    [SET_DIALOG_STATUS](state, payload) {
      state.db_dialog = payload;
    },

    [SET_CUSTOMER](state, payload) {
      state.db_selection_customer = cloneDeep(payload);
    },
    [SET_PROPERTY](state, payload) {
      state.db_selection_property = cloneDeep(payload);
    },
    [SET_PROPERTY_CONTACT](state, payload) {
      state.db_selection_property_contact = cloneDeep(payload);
    },
    [SET_BILLING](state, payload) {
      state.db_selection_billing = cloneDeep(payload);
    },
    [SET_BILLING_CONTACT](state, payload) {
      state.db_selection_billing_contact = cloneDeep(payload);
    },
  },
};
